<template>
  <div :class="classNames" @click="onClick">
    <slot name="icon" />
    <BittsAvatar
      v-if="hasImage"
      :image-source="imageSource"
      :org="org"
      size="x-small"
      class="mr-4"
    />
    <span class="flex-1">{{ text }}</span>
    <span
      v-if="removable"
      class="bitts-chip__icon"
      @[addIconClickHandler]="onIconClick"
    >
      <FontAwesomeIcon
        :icon="['far', 'circle-xmark']"
        :class="danger ? 'text-danger-accent' : 'text-neutral-text-placeholder'"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        aria-hidden="true"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import { TOrganization } from '../BittsAvatar/types';

export interface Props {
  danger?: boolean;
  text: string;
  selected?: boolean;
  imageSource?: string;
  org?: TOrganization | null;
  removable?: boolean;
  tableCellStyle?: boolean;
  clickableIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  danger: false,
  selected: false,
  imageSource: '',
  org: null,
  removable: true,
  tableCellStyle: false,
  clickableIcon: false,
});

const emit = defineEmits(['click', 'icon-click']);

const classNames = computed(() => ({
  'bitts-chip': true,
  'bitts-chip-selected': props.selected,
  'bitts-chip__danger': props.danger,
  'bitts-chip__table-cell': props.tableCellStyle,
}));
const hasImage = computed(() => !!props.imageSource || !!props.org);
const addIconClickHandler = computed(() =>
  props.clickableIcon ? 'click' : null,
);

const onClick = (e: Event) => {
  emit('click', e);
};

const onIconClick = (e: Event) => {
  emit('icon-click', e);
};
</script>

<style lang="pcss">
.bitts-chip {
  @apply inline-flex items-center h-24 px-8 text-sm
  rounded-bts-lg bg-neutral-background cursor-pointer;

  &:hover:not(.bitts-chip__danger):not(.bitts-chip__table-cell) {
    @apply bg-blue-100;

    .bitts-chip__icon {
      @apply text-brand-blue;
    }
  }

  &-selected {
    @apply bg-brand-blue text-blue-100;
  }

  .bitts-chip__icon {
    @apply text-neutral-text-placeholder ml-8;
  }
}

.bitts-chip__danger {
  @apply bg-danger-background-weak;
}

.bitts-chip__table-cell {
  @apply bg-neutral-background rounded-bts-base cursor-default;
  white-space: nowrap;
}
</style>
